<template>
  <div
    id="index"
    ref="appRef"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div class="user_info host-body" v-else>
        <!-- 个人信息 -->
        <div class="dialog_box">
          <div style="cursor: pointer; padding-left: 20px" @click="handleBack">
            <i class="el-icon-arrow-left"></i>
            返回
          </div>
          <div class="flex_col">
            <img
              src="@/assets/default-avatar.png"
              width="200"
              height="200"
              style="margin: 25px auto; border-radius: 13px"
              alt=""
            />
            <!-- 个人信息 -->
            <table class="tableStyle tCenter" style="width: 280px">
              <tr class="tableTitle">
                <td>姓名</td>
                <td class="fw">{{ handleString(userInfo.name)  }}</td>
              </tr>
              <tr class="tableTitle">
                <td>性别</td>
                <td class="fw">
                  {{ userInfo.genderType === "MALE" ? "男" : "女" }}
                </td>
              </tr>
              <tr class="tableTitle">
                <td>年龄</td>
                <td class="fw">{{ userInfo.age }}岁</td>
              </tr>
              <tr class="tableTitle">
                <td>联系电话</td>
                <td class="fw">{{ handleString(userInfo.phone, 4) }}</td>
              </tr>
              <tr class="tableTitle">
                <td>身份证号</td>
                <td class="fw">{{ handleString(userInfo.idCard, 6)  }}</td>
              </tr>
              <tr class="tableTitle">
                <td>签约医师</td>
                <td class="fw">{{ handleString(userInfo.doctorName) }}</td>
              </tr>
              <tr class="tableTitle">
                <td>医共体</td>
                <td class="fw">{{ handleString(userInfo.parentHospitalName) }}</td>
              </tr>
              <tr class="tableTitle">
                <td>隶属中心</td>
                <td class="fw">{{ handleString(userInfo.centerHospitalName) }}</td>
              </tr>
              <tr class="tableTitle">
                <td>医院名称</td>
                <td class="fw">{{ handleString(userInfo.hospitalName) }}</td>
              </tr>
            </table>
            <div class="report_space" @click="handleGotoReport">
              <span class="color">查看健康评估报告</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <!-- 心电监测记录 -->
        <div class="dialog_box">
          <h3>心电监测记录</h3>
          <div class="box-scroll" v-if="!fullscreenLoading">
            <div v-if="heartList.length">
              <div v-for="(item, index) in heartList" :key="index">
                <div class="flex-eve" style="margin: 32px 0">
                  <div class="fz16">
                    <span class="fw">心率</span>
                    <span style="font-size: 14px" v-if="item.ecgResult">
                      ( {{ item.ecgResult ? item.ecgResult : '--' }} )</span
                    >
                  </div>
                  <div class="flex" style="margin-left: 30px; min-width: 70px">
                    <div>
                      <span class="fz12">心率</span>
                      <div class="fz12">bpm</div>
                    </div>
                    <div class="number flex-cc fw">{{ item.value ?  item.value  : '--'  }}</div>
                  </div>
                </div>
                <div class="flex-bet fz12" style="margin-bottom: 15px">
                  <div>设备检测 | 24小时监测</div>
                  <div>检测日期：{{ monitorTime(item.monitorTime) }}</div>
                </div>
                <div
                  v-if="item.fileImagePath"
                  style="width: 100%; height: 112px"
                >
                  <img
                    :src="item.fileImagePath"
                    style="width: 100%; height: 100%"
                  />
                </div>
                <!-- 心电进度条 -->
                <div class="progress">
                  <div class="glucose">
                    <div class="glucose-low">
                      稍慢
                      <div
                        class="space"
                        v-if="item.value < 60"
                        :style="{
                          marginLeft: (item.value / 59) * 100 + '%',
                        }"
                      >
                        {{ item.value }}
                      </div>
                    </div>
                    <div class="glucose-normal">
                      正常
                      <div class="standard-low">60</div>
                      <div class="standard-high">100</div>
                      <div
                        class="space"
                        v-if="item.value >= 60 && item.value <= 100"
                        :style="{
                          marginLeft: ((item.value - 60) / 40) * 100 + '%',
                        }"
                      >
                        {{ item.value }}
                      </div>
                    </div>
                    <div class="glucose-high">
                      稍快
                      <div
                        class="space"
                        v-if="item.value > 100 && item.value <= 200"
                        :style="{
                          marginLeft: ((item.value - 100) / 100) * 100 + '%',
                        }"
                      >
                        {{ item.value }}
                      </div>
                      <div
                        class="space"
                        v-if="item.value > 200"
                        :style="{ marginLeft: '100%' }"
                      >
                        {{ item.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-else class="flexCC" style="height: 90%">暂无数据</div> -->
          </div>
        </div>
        <!-- 血压监测记录 -->
        <div class="dialog_box">
          <h3>血压监测记录</h3>
          <div class="box-scroll" v-if="!fullscreenLoading">
            <div v-if="pressureList.length">
              <div v-for="(item, index) in pressureList" :key="index">
                <div class="flex-eve" style="margin: 32px 0">
                  <div class="fz16">血压</div>
                  <div class="flex-bet" style="width: 222px">
                    <div class="flex-cc">
                      <div>
                        <span class="fz12">收缩压</span>
                        <div class="fz12">mmHg</div>
                      </div>
                      <div class="number flex-cc fw">{{ item.highValue ? item.highValue : '--' }}</div>
                    </div>
                    <div class="flex-cc">
                      <div>
                        <span class="fz12">舒张压</span>
                        <div class="fz12">mmHg</div>
                      </div>
                      <div class="number flex-cc fw">{{ item.lowValue ? item.lowValue : '--' }}</div>
                    </div>
                  </div>
                </div>
                <div class="flex-bet fz12" style="margin-bottom: 15px">
                  <div>设备监测</div>
                  <div>检测日期：{{ monitorTime(item.monitorTime) }}</div>
                </div>
                <div class="swiper">
                  <div class="systolic-pressure diastolic-pressure">
                    <!-- 收缩压 -->
                    <div class="low-tension">
                      <div class="swiper-unit">
                        <div class="swiper-title">低压区</div>

                        <div class="swiper-value">99≤</div>
                      </div>
                      <div class="block">
                        <div
                          v-if="item.highValue <= 99 && item.highValue"
                          class="block-value"
                          :style="{
                            marginLeft: `calc(${
                              (item.highValue / 99) * 100
                            }% - 14px)`,
                          }"
                        >
                          {{ item.highValue }}
                        </div>
                      </div>
                    </div>
                    <div class="normal">
                      <div class="swiper-unit">
                        <div class="swiper-title">正常</div>
                        <div class="swiper-value">100-139</div>
                      </div>
                      <div class="block">
                        <div
                          class="block-value"
                          v-if="item.highValue >= 100 && item.highValue <= 139"
                          :style="{
                            marginLeft: `calc(${
                              ((item.highValue - 100) / 40) * 98
                            }% - 14px)`,
                          }"
                        >
                          {{ item.highValue }}
                        </div>
                      </div>
                    </div>
                    <div class="high-tension">
                      <div class="swiper-unit">
                        <div class="swiper-title">高压区</div>
                        <div class="swiper-value">≥140</div>
                      </div>
                      <div class="block">
                        <div
                          class="block-value"
                          v-if="item.highValue >= 140 && item.highValue <= 200"
                          :style="{
                            marginLeft: `calc(${
                              ((item.highValue - 140) / 60) * 100
                            }% - 14px)`,
                          }"
                        >
                          {{ item.highValue }}
                        </div>
                        <div
                          class="block-value"
                          v-if="item.highValue > 200"
                          :style="{ marginLeft: '100%' }"
                        >
                          {{ item.highValue }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="systolic-pressure">
                    <!-- 舒张压 -->
                    <div class="low-tension">
                      <div class="swiper-unit">
                        <div class="swiper-title">低压区</div>
                        <div class="swiper-value">59≤</div>
                      </div>
                      <div class="block">
                        <div
                          v-if="item.lowValue < 60 && item.lowValue"
                          class="block-value"
                          :style="{
                            marginLeft:`calc(${(item.lowValue / 59) * 100}% - 14px)`,
                          }"
                        >
                          {{ item.lowValue }}
                        </div>
                      </div>
                    </div>
                    <div class="normal">
                      <div class="swiper-unit">
                        <div class="swiper-title">正常</div>
                        <div class="swiper-value">60-89</div>
                      </div>
                      <div class="block">
                        <div
                          class="block-value"
                          v-if="item.lowValue >= 60 && item.lowValue <= 89"
                          :style="{
                            marginLeft:`calc(${((item.lowValue - 60) / 30) * 98}% - 14px)`,
                          }"
                        >
                          {{ item.lowValue }}
                        </div>
                      </div>
                    </div>
                    <div class="high-tension">
                      <div class="swiper-unit">
                        <div class="swiper-title">高压区</div>
                        <div class="swiper-value">≥90</div>
                      </div>
                      <div class="block">
                        <div
                          class="block-value"
                          v-if="item.lowValue >= 90 && item.lowValue <= 200"
                          :style="{
                            marginLeft: `calc${((item.lowValue - 90) / 110) * 100}% - 14px)`
                          }"
                        >
                          {{ item.lowValue }}
                        </div>
                        <div
                          class="block-value"
                          v-if="item.lowValue > 200"
                          :style="{ marginLeft: '100%' }"
                        >
                          {{ item.lowValue }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-else class="flexCC" style="height: 90%">暂无数据</div> -->
          </div>
        </div>
        <!-- 血糖监测记录 -->
        <div class="dialog_box">
          <h3>血糖监测记录</h3>
          <div class="box-scroll" v-if="!fullscreenLoading">
            <div v-if="glucoseList.length">
              <div v-for="(item, index) in glucoseList" :key="index">
                <div class="flex-eve" style="margin: 32px 0">
                  <div class="fz16">血糖</div>
                  <div class="flex">
                    <div>
                      <span class="fz12">血糖值</span>
                      <div class="fz12">mmol/L</div>
                    </div>
                    <div class="number flex-cc fw">{{ item.value ?item.value : '--' }}</div>
                  </div>
                </div>
                <div class="flex-bet fz12" style="margin-bottom: 15px">
                  <div>设备检测</div>
                  <div>检测日期：{{ monitorTime(item.monitorTime) }}</div>
                </div>
                <!-- 血糖进度条 -->
                <div class="progress">
                  <div class="glucose">
                    <div class="glucose-low">
                      低血糖
                      <div
                        class="space"
                        v-if="item.value <= 2.8"
                        :style="{
                          marginLeft: (item.value / 2.8) * 100 + '%',
                        }"
                      >
                        {{ item.value }}
                      </div>
                    </div>
                    <div class="glucose-normal">
                      正常
                      <div class="standard-low">2.8</div>
                      <div class="standard-high">7.0</div>
                      <div
                        class="space"
                        v-if="item.value > 2.8 && item.value <= 7.0"
                        :style="{
                          marginLeft: ((item.value - 2.8) / 4.1) * 100 + '%',
                        }"
                      >
                        {{ item.value }}
                      </div>
                    </div>
                    <div class="glucose-high">
                      高血糖
                      <div
                        class="space"
                        v-if="item.value > 7.0 && item.value <= 10"
                        :style="{
                          marginLeft: ((item.value - 7.1) / 3.0) * 100 + '%',
                        }"
                      >
                        {{ item.value }}
                      </div>
                      <div
                        class="space"
                        v-if="item.value > 10"
                        :style="{ marginLeft: '100%' }"
                      >
                        {{ item.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-else class="flexCC" style="height: 90%">暂无数据</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "@/utils/drawMixin";
import { particulars } from "@/api/particulars";
import { monitorTime, handleString } from "@/utils";

export default {
  mixins: [drawMixin],

  data() {
    return {
      handleString,
      monitorTime,
      loading: false,
      fullscreenLoading: true,
      userInfo: {}, // 个人信息
      heartList: [], // 心电记录
      pressureList: [], // 血压记录
      glucoseList: [], // 血糖记录
      bloodPressure: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      particulars.getInfo(this.$route.query.userId).then((res) => {
        this.userInfo = res.data; // 个人信息
        this.heartList = res.data.heartRateVOList; // 心电记录
        this.pressureList = res.data.pressureVOList; // 血压记录
        this.glucoseList = res.data.glucoseVOList; // 血糖记录
        this.fullscreenLoading = false;
      });
    },
    // 查看健康评估报告
    handleGotoReport() {
      this.$router.push(
        `/report?userId=${this.$route.query.userId}&hospitalName=${this.userInfo.hospitalName}`
      );
    },

    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/table.scss";
.bg {
  background-image: url("~@/assets/detail_bg.png") !important;
  background-size: 100% 100%;
  background-position: center center;
}
.user_info {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .dialog_box {
    width: 382px;
    height: 898px;
    padding: 48px 0;
    box-sizing: border-box;
    border-radius: 30px;
    color: #fff;
    background: rgba(0, 179, 250, 0.1);
    box-shadow: 0px 20px 30px 0px rgba(23, 23, 23, 0.5);
  }
  h3 {
    padding: 0 31px 20px;
  }
  .box-scroll {
    height: 98%;
    overflow-y: auto;
    padding: 0 31px !important;
  }
}

.report_space {
  width: 211px;
  height: 30px;
  margin-top: 30px;
  background: url("~@/assets/report_space.png");
  background-size: cover;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
}

.number {
  font-size: 34px;
  margin-left: 8px;
}
// 进度条
.progress {
  color: #fff;
  margin-bottom: 64px;
  .glucose {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    font-size: 14px;
    font-weight: 700;
    &-low,
    &-normal,
    &-high {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .space {
        position: absolute;
        left: -29px;
        top: -42px;
        width: 58px;
        height: 56px;
        display: flex;
        justify-content: center;
        padding-top: 15px;
        box-sizing: border-box;
        font-weight: 700;
        color: #000;
        background: url("~@/assets/glucose.png") no-repeat;
        background-size: 130% 100%;
        background-position: center center;
      }
    }
    &-low {
      width: 103px;
      height: 36px;
      background: #d5b436;
    }
    &-normal {
      position: relative;
      width: 129px;
      height: 36px;
      background: #2d9386;
      .standard-low {
        position: absolute;
        bottom: -21px;
        left: -10px;
      }
      .standard-high {
        position: absolute;
        bottom: -21px;
        right: -8px;
      }
    }
    &-high {
      width: 103px;
      height: 36px;
      background: #c3513a;
    }
  }
  .swiper {
    display: flex;
    flex-direction: column;
    .systolic-pressure {
      display: flex;
      justify-content: center;
      height: 92px;
      font-size: 14px;
      font-weight: 700;
      border: 1.5px solid rgba(255, 255, 255, 0.5);
      .swiper-unit {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
      }
      .low-tension {
        position: relative;
        width: 103px;
        color: #d5b436;
        .block {
          position: absolute;
          bottom: 15px;
          right: 0;
          width: 90%;
          height: 6px;
          background: #d5b436;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          .block-value {
            position: absolute;
            left: -5px;
            bottom: -15px;
            width: 40px;
            height: 34px;
            background: url("~@/assets/fixed_space.png") no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-weight: 700;
            z-index: 99999;
            div {
              z-index: 999;
            }
          }
        }
      }
      .normal {
        position: relative;
        width: 127px;
        height: 90px;
        color: #2d9386;
        border-left: 1.5px solid rgba(255, 255, 255, 0.5);
        border-right: 1.5px solid rgba(255, 255, 255, 0.5);
        .block {
          position: absolute;
          bottom: 16px;
          right: -1px;
          left: -1px;
          width: 130px;
          height: 6px;
          background-color: #2d9386;
          .block-value {
            position: absolute;
            left: -5px;
            bottom: -15px;
            width: 40px;
            height: 34px;
            background: url("~@/assets/fixed_space.png") no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-weight: 700;
            z-index: 99999;
            div {
              z-index: 999;
            }
          }
        }
      }
      .high-tension {
        position: relative;
        width: 103px;
        color: #c3513a;
        .block {
          position: absolute;
          bottom: 15px;
          left: 0;
          width: 90%;
          height: 6px;
          background: #c3513a;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          .block-value {
            position: absolute;
            left: -5px;
            bottom: -15px;
            width: 40px;
            height: 34px;
            background: url("~@/assets/fixed_space.png") no-repeat;
            background-size: 100% 100%;
            background-position: center center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            font-weight: 700;
            z-index: 99999;
            div {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
// 进度条2
.swiper {
  display: flex;
  flex-direction: column;
  .systolic-pressure {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    height: 92px;
    font-size: 14px;
    font-weight: 700;
    border: 1.5px solid #566263;
    .swiper-unit {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }
    .low-tension {
      position: relative;
      width: 90px;
      color: #ffd435;
      .block {
        position: absolute;
        bottom: 15px;
        right: 0;
        width: 90%;
        height: 6px;
        background-color: #ffd435;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        .block-value {
          position: absolute;
          left: -5px;
          bottom: -15px;
          width: 40px;
          height: 34px;
          background: url("~@/assets/fixed_space.png") no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-weight: 700;
          z-index: 99999;
          div {
            z-index: 999;
          }
        }
      }
    }
    .normal {
      position: relative;
      width: 127px;
      height: 90px;
      color: #00ffdb;
      border-left: 1.5px solid #566263;
      border-right: 1.5px solid #566263;
      .block {
        position: absolute;
        bottom: 16px;
        right: -1px;
        left: -1px;
        width: 127px;
        height: 6px;
        background-color: #00ffdb;
        .block-value {
          position: absolute;
          left: -5px;
          bottom: -15px;
          width: 40px;
          height: 34px;
          background: url("~@/assets/fixed_space.png") no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-weight: 700;
          z-index: 99999;
          div {
            z-index: 999;
          }
        }
      }
    }
    .high-tension {
      position: relative;
      width: 90px;
      color: #ff5833;
      .block {
        position: absolute;
        bottom: 15px;
        left: 0;
        width: 90%;
        height: 6px;
        background-color: #ff5833;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        .block-value {
          position: absolute;
          left: -5px;
          bottom: -15px;
          width: 40px;
          height: 34px;
          background: url("~@/assets/fixed_space.png") no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000;
          font-weight: 700;
          z-index: 99999;
          div {
            z-index: 999;
          }
        }
      }
    }
  }
}
</style>
