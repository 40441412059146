import request from '@/utils/request'

/**
 * 个人信息 风险报告个人信息 风险报告
 */
export const particulars = {

    // 个人信息详情
    getInfo(userId) {
        return request({
            url: `v/info/${userId}`,
            method: 'get',
        })
    },
    // 风险报告个人信息
    getReportUserInfo(userId) {
        return request({
            url: `v/info/patient/${userId}`,
            method: 'get',
        })
    },
    // 风险报告列表
    getReport(userId) {
        return request({
            url: `v/info/risk/report/${userId}`,
            method: 'get',
        })
    },
}
